$glass: rgba(255, 255, 255, 0.2);
$glass-icon: rgba(255, 255, 255, 0.3);
$option: #320a28;
.select {
  /* <select> styles */
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;

  /* Personalize */

  &[value="null"] {
    color: #999;
  }
  cursor: pointer;
  padding: 14px 20px 16px;
  border: 1px solid #eee;
  border-radius: 10px;

  /* <option> colors */
  option {
    // color: inherit;
    background-color: $option;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* Remove IE arrow */
  &::-ms-expand {
    // display: none;
  }
}
