.Filename {
  .title {
    word-break: break-all;
    position: relative;
    display: inline;
  }

  .change-title {
    position: absolute;
    cursor: pointer;
    transition: all 150ms linear;
    left: -120px;
    color: #999;
    top: -3px;
    font-weight: 500;
    &:hover {
      color: #0055ff;
    }
  }
}
