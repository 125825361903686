@import "vars";
@import "fonts";
@import "libs";
@import "category";
@import "~bootstrap/scss/bootstrap";
::placeholder {
  color: #666;
}

::selection {
  background-color: $accent;
  color: #fff;
}

.mr-10 {
  margin-right: 10px;
}

body {
  font-size: 12pt;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: $default-font;
  overflow: auto;
  color: $text;

  input,
  textarea {
    border: none;
    outline: none;
    border-bottom: 1px solid $gray;
    padding-bottom: 5px;
    color: $text;
    min-width: 300px;

    // &:hover {
    //   border: none;
    //   border-bottom: 1px solid $accent !important;
    //   background-color: transparent;
    // }
  }
}

.particles {
  height: 100vh;
  position: absolute;

  div {
    height: 100vh;
  }
}

.gray {
  color: $gray;
}

.dark-gray {
  color: #999;
}

.hover-red {
  &:hover {
    color: $red;
  }
}

.top-line {
  width: 100%;

  text-align: right;
  background-color: $white;
  text-decoration: none;
  border-bottom: 1px solid $gray;

  a {
    padding: 14px 30px 20px 30px;
    transition: all 200ms linear;
    margin: 0;
    font-weight: 500;

    &:hover {
      color: $accent;
      text-decoration: none;
    }

    font-size: 16px;
    color: $text;
  }
}

.RadioElement {
  display: flex;
  align-items: center;

  .element {
    background-color: #f2f2f2;
    border-radius: 3px;
    padding: 3px 8px;
    margin: 0 5px;
  }
}

#login,
#password {
  border: 0 !important;
  border-bottom: 1px solid $accent !important;
  padding-bottom: 5px !important;
}

input {
  border: none;
  border-bottom: 1px solid $gray;
  padding-bottom: 5px;
  color: $text;
  min-width: 300px;

  &:hover {
    border-bottom-color: $accent;
  }

  &::placeholder {
    color: $darkgray;
  }
}

.Input {
  padding-bottom: 7px;
  margin: 0 10px;
}

@import "login";
@import "error";

.accent-color {
  color: $accent !important;
}

.lightgray {
  color: #f9f9f9 !important;
}

.fw-normal {
  font-weight: normal !important;
}

.fz-28 {
  font-size: 28px !important;
}

.accent {
  &-hvr {
    cursor: pointer;

    &:hover {
      color: $accent !important;
    }
  }
}

div.btn {
  border: 1px solid $accent;

  &:hover {
    background-color: $accent;
    color: $white;
  }
}

.close-icon {
  div {
    height: 15px;
    width: 15px;
    opacity: 0.8;
    background-color: $text;
    position: relative;
    right: -10px;
  }

  width: 100%;
  position: relative;
  top: -0px;
  display: flex;
  justify-content: flex-end;
}

.deleteTemplate {
  margin-top: 5px;
  transition: all 200ms ease-in-out;

  &:hover {
    color: $red;
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 12pt;
  color: $black;
  background-color: $white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;

  .icon {
    height: 27px;
    width: 27px;
    margin-right: 5px;
  }
}

.inputfile:focus + label,
.inputfile + label:hover {
  color: $accent;
}

.inputfile + label {
  cursor: pointer;
  /* "hand" cursor */
}

.submit-btn {
  width: fit-content;
  padding: 8px 15px;
}

.uploadForm {
  border: 1px solid $accent;
  border-radius: 4px;

  & > label {
    margin: 5px;
  }

  & > span {
    padding: 5px 5px;
  }

  .submit-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 0;
    border: none !important;
    border-top: 1px solid $accent !important;
  }
}

.deleteTemplate {
  top: 23px;
  opacity: 0.8;
  background-color: $red !important;
  height: 23px !important;
  width: 23px !important;
}

.radioContainer {
  display: flex;
  align-items: center;

  .circle {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 2px solid $gray;
    border-radius: 100%;
  }

  .circle-inside {
    width: 10px;
    height: 10px;
    background-color: $accent;
    border-radius: 100%;
    position: relative;
    left: -25px;
  }

  &:hover {
    .labelRadio {
      color: $accent;
    }

    .circle {
      border-color: $accent;
    }
  }

  .labelRadio {
    margin-bottom: 0;
  }
}

.complexListKey {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding-left: 15px;
  border-left: 2px solid $gray;

  &:hover {
    border-left-color: $accent;
  }
}

a {
  color: $accent;
}

a:hover {
  color: $accent;
}

.addText {
  color: $darkgray;
  font-size: 11pt;
  position: relative;
  left: -80px;
  cursor: pointer;
}

.longInput {
  width: 500px;
  // position: absu;
  padding-right: 90px;
}

.Oprosnik {
  overflow-y: initial;
  padding-top: 1rem;
  margin: 0 auto;
  max-width: 800px;
  width: inherit;
  font-size: 18px;
  margin-bottom: 300px;

  .bold {
    font-weight: bold !important;
  }

  input[type="radio"]:checked + .label {
    border-color: #dc3545;

    &::after {
      background-color: #dc3545;
    }
  }

  .logotype {
    img {
      height: 60px;
    }
  }

  .tel {
    margin-top: 20px;
  }

  .radio1 {
    margin-left: 0px;
  }

  .RadioElement input[type="checkbox"]:checked + .cbx {
    background-color: #dc3545;
  }

  .content {
    margin-bottom: 70px;
  }

  p.darkgray {
    font-weight: 500;
  }

  h1 {
    font-weight: 500;
    line-height: 110%;
    font-size: 48px;
    width: 100%;
    max-width: 800px;
    padding: 0;
    font-family: "MullerNarrow";
  }

  .Element {
    margin: 20px 0;
  }

  .Input {
    padding-bottom: 0;
    font-weight: normal;
  }

  .tip-input {
    font-style: normal;

    &::placeholder {
      color: #ccc;
    }
  }

  .tip {
    font-weight: 500;
    color: #00aeef;
  }

  .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;

    .tel {
      text-decoration: underline;
      font-size: 18px;
    }

    * {
      font-size: 1.75rem;
      color: $text;
    }

    .type {
      text-decoration: none;
    }

    font-family: "MullerNarrow";

    // height: 33px;
  }

  .Phone {
    margin-top: 30px;
    margin-bottom: 100px;
    font-size: 24px;

    a {
      text-decoration: underline;
      color: $text;
      position: relative;
      top: 5px;
    }
  }

  textarea {
    background-color: #f9f9f9 !important;
    border: 1px solid #ccc !important;
    transition: border-color 500ms ease-in-out;
    padding: 8px 46px 8px 10px;
    margin-top: 10px;
    min-width: inherit;

    &::placeholder {
      font-style: initial;
    }

    &:hover {
      border-color: #5ba0da !important;
    }
  }

  .ElementContainer {
    border-left: none;
    padding-left: 0;
  }
}

.overlay {
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 10000000000000;

  .wrapper {
    overflow-y: scroll;
  }

  .form {
    min-width: 300px;
    min-height: 110px;
    height: fit-content;
    max-height: 90vh;
    background: #fff;
    max-width: 600px;
    padding: 30px;
    display: block;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;

    overflow-y: scroll;
  }

  .input-block {
    margin: 0 0 15px 0;
  }

  .input-block.required {
    label {
      &:after {
        content: "*";
        position: absolute;
        margin: 0 0 0 2px;
        color: #f11a48;
        font-size: 16px;
      }
    }
  }

  label {
    display: block;
    padding: 0;
    margin-bottom: 6px;
    line-height: 19px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 13px;
    color: #333;
    text-align: left;
    white-space: normal;
  }

  input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 8px 46px 8px 10px;
    border: 1px solid #ccc;
    min-width: inherit;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: #f9f9f9;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: normal;
    outline: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: border-color ease-in-out 0.5s;
    -moz-transition: border-color ease-in-out 0.5s;
    transition: border-color ease-in-out 0.5s;
    font-size: 15px;
    font-weight: normal;
    color: #333;

    &:hover {
      background: #f9f9f9;
      border: 1px solid #5ba0da !important;
    }
  }

  .title-row {
    display: flex;
    text-align: center;
    margin: 0 0 15px 0;
    width: 100%;
  }

  .title {
    flex: 1;
    font-size: 44px;
    line-height: 110%;
    font-family: "MullerNarrow";
    text-align: left;
    min-width: inherit;
  }

  .close-container {
    outline: 2px solid #e2e2e2;
    width: 20px;
    min-width: 20px;
    height: 20px;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;

    transition: outline-color 500ms ease-in-out;

    &:hover {
      outline-color: #aaa;
    }
  }

  .close {
    background-color: #333;
    width: 12px;
  }

  .submit {
    position: relative;
    display: inline-block;
    padding: 10px 18px;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1px;
    border: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: transparent;
    background-color: #00aeef;
    color: white;
    width: fit-content;
    margin: 0 auto;
    vertical-align: middle;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    outline: 0;
    cursor: pointer;
    opacity: 0.9;
    margin: 20px auto;

    &.locked {
      background-color: #ccc;

      cursor: default;
    }
  }
}

.MainScreen {
  a:hover {
    text-decoration: none;
  }

  .Tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .MenuBlock {
      cursor: pointer;
      margin: 10px;

      .img-container {
        .img {
          width: 100%;
          height: 500px;
          object-fit: cover;
          max-width: 500px;
          max-height: 500px;
        }
      }

      .link {
        font: 36px "MullerNarrow";
        color: $text;
        border-bottom: 1px solid $white;
        padding-bottom: 5px;
        width: fit-content;
        transition: color 0.3s linear;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        .link {
          text-decoration: none;
          color: $accent;
        }
      }
    }
  }
}

.QsForCheck {
  padding: 30px 20px;

  .container {
    max-width: inherit;
    font-size: 18px;

    .thead {
      font-weight: bold;
    }

    .Block {
      margin: 20px auto;
      padding: 20px;
      // outline: 1px solid $gray;
      font-size: 18px;

      .right {
        padding-right: 0px;
      }

      max-width: 1000px;

      .grid-container {
        border: 1px solid #eee;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas: "ID Name Comment" "DateTime Name Comment" ". Address Requesities" ". Email Requesities" "Check Total Requesities";

        &.editor {
          grid-template-rows: 1fr 1fr 1fr 1fr;
          grid-template-areas: "ID Name Comment" "DateTime Name Comment" "Address Email Comment" ". Requesities Requesities";
        }

        & > div {
          // min-width: 150px;
          border: 1px solid #eee;
          padding: 0 20px;

          .sign {
            transition: all 500ms ease-in-out;
            height: 29px;
            // opacity: 0;
            align-items: center;
            clear: both;
            font-weight: lighter;
            // color: $gray;
            color: #d0d0d0;

            &.right {
              float: right;
            }

            &.left {
              float: left;
              margin-right: auto;
            }
          }

          // &:hover {
          //   .sign {
          //     color: #777;
          //   }
          // }
        }
      }

      input {
        min-width: fit-content;
      }

      textarea {
        width: 100%;
        height: 80px;
        min-width: initial;
      }

      .ID {
        grid-area: ID;
      }

      .Name {
        grid-area: Name;
        font-weight: bold;
      }

      .DateTime {
        grid-area: DateTime;
        border-bottom: none;
      }

      .Address {
        grid-area: Address;
      }

      .Email {
        grid-area: Email;
      }

      .Total {
        grid-area: Total;
      }

      .Check {
        grid-area: Check;

        $span_height: 36px;
        $animation_duration: 300ms;

        .span-container {
          display: block;
          overflow: hidden;
          cursor: pointer;
          width: fit-content;
          height: $span_height;
          max-height: $span_height;

          span {
            display: block;
            height: $span_height;
            color: $accent;
            // border-bottom: 1px solid $gray;
            // transition: all $animation_duration ease-in-out;
            width: fit-content;
            // text-align: right;
            // margin-left: auto;
          }

          &:hover {
            span {
              transform: translateY(-$span_height);
            }

            &:after {
              display: block;
              transform: translateY(-$span_height);
              // transition: all $animation_duration ease-in-out;
              color: #0056b3;
              background-color: white;
              cursor: pointer;
              content: "Оплатить";
              // animation: $animation_duration e ase-in-out 0s move_word;
            }

            &.payed:after {
              color: #ff9e39;
              content: "Снять оплату";
            }

            &.not-payed:after {
              color: #52c36a;
              content: "Проставить оплату";
            }
          }

          @keyframes move_word {
            from {
              transform: translateY(0px);
            }

            to {
              transform: translateY(-$span_height);
            }
          }
        }
      }

      .Requesities {
        grid-area: Requesities;
      }

      .Comment {
        grid-area: Comment;
        padding: 0 20px 20px 20px !important;
        // max-height: 100px;
      }

      .row {
        min-height: 30px;
        padding: 10px;
      }

      .right {
        display: flex;
        color: #b3b3b3;
        justify-content: flex-end;
        text-align: right;
      }

      .Email {
        color: $text;
        text-decoration: none;

        &:hover {
          color: $accent;
        }
      }
    }
  }
}

// .checkbox {
//   outline: 1px solid $gray;
//   width: 30px;
//   height: 30px;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   &.checked {
//     outline-color: $accent;
//     .check {
//       background-color: $white;
//     }
//   }
//   .check {
//     background-color: $accent;
//   }
// }

.cope_text {
  overflow: hidden;
  line-height: 20px;
  // height: fit-content;
}

.cope_text p {
  margin: 0 0 0 0;
}

.line-clamp {
  // display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  transition: all 300ms ease-in-out;
}

.Filters {
  max-width: 1000px;
  margin: 0 auto 50px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;

  // flex-wrap: wrap;
  .href {
    color: white;
    background: #48b1fd;
    width: 150px;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left: 10px;
    margin-top: 10px;
    padding: 10px 20px;

    &:hover {
      background: #0056b3;
    }
  }

  .radio-block,
  .href {
    display: flex;
    align-items: center;
    padding: 15px 10px;

    input {
      width: initial;
      min-width: initial;
      margin-right: 5px;
    }

    label {
      margin-right: 20px;
    }
  }

  .input-block {
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    input {
      border: 1px solid $gray;
    }

    label {
      color: #ccc;
    }
  }
}

.topline-link {
  margin-right: 30px;
}

.missed {
  .ignoreAlert {
    font-weight: bold;

    &.after {
      margin-top: 20px;
    }
  }

  .MissedField {
    font-size: 16px;
    line-height: 25px;
    padding: 5px 0;
    border-bottom: 1px solid $gray;
    color: $darkgray;
  }

  .button {
    position: relative;
    display: inline-block;
    padding: 10px 18px;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1px;
    border: 0;
    border-radius: 2px;
    background: transparent;
    background-color: #00aeef;
    color: white;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    vertical-align: middle;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    outline: 0;
    cursor: pointer;
    opacity: 0.9;
    margin: 20px auto;

    &.ignore {
      background-color: #737373;
      margin-right: 30px;

      &:hover {
        background-color: #b3b3b3;
      }
    }

    &.return {
      &:hover {
        background-color: #1fc2ff;
      }
    }
  }
}

.inputsBlock {
  margin-top: 10px;
}

.UsersScreen {
  .subtitle {
    padding: 10px;
    font-weight: bold;
  }
}

.CreateUserRow {
  display: flex;

  flex-direction: column;

  .RadioElement {
    .label {
      display: flex;
      // justify-content: center;
      flex-direction: row-reverse;
      align-items: center;

      &.choosen {
        color: $accent;
      }

      .Box {
        margin-right: 10px;
      }
    }
  }

  .Input {
    margin: 0 10px;
  }

  & > * {
    padding: 10px 0;
  }

  .label {
    display: flex;
    margin: 5px 10px;
  }

  .button {
    padding: 10px 20px;
    outline: none;
    border: 1px solid $gray;
    background: white;
    color: $gray;
    transition: all 400ms ease-in-out;

    &.active {
      cursor: pointer;
      color: $white;
      background-color: $accent;
      border: 1px solid $accent;
    }
  }
}

.UserRow {
  margin: 40px 0;
  border: 1px solid $gray;
  padding: 40px;

  .password-col {
    display: flex;
  }

  .tabs {
    display: flex;
    flex-wrap: wrap;

    margin-top: 15px;

    .CategoryTab {
      width: fit-content;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      padding: 5px 15px;
      background-color: #eee;
      margin-right: 15px;
      border-radius: 5px;

      .close {
        width: 12px;
        height: 12px;
        margin-left: 15px;
        background-color: $text;
      }
    }
  }

  .Select {
    height: 40px;
    margin-top: 20px;
    border-radius: 5px;
    padding: 0 10px;
    max-width: 200px;

    &:hover {
    }
  }

  .Cell {
    margin-right: 50px;
    width: fit-content;
    min-width: 150px;

    .field {
      color: $text;
    }

    .label {
      font-weight: 300;
      color: $darkgray;
      min-height: 27px;
    }
  }

  .fio {
    font-weight: bold;
  }

  .status {
    color: $gray;
  }

  .showPassword {
    .field {
      cursor: pointer;

      &:hover {
        color: $accent;
      }
    }
  }

  .delete {
    .field {
      color: $gray;
      cursor: pointer;

      &:hover {
        color: $red;
      }
    }
  }
}

.exit {
  margin-left: auto;
}

.top-block {
  margin: 40px 0;
  padding: 0 15px;
  display: flex;

  .CategoriesList {
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    .add-block {
      margin-top: 2px;
      margin-bottom: 44px;
    }

    .button {
      padding: 10px 30px;
      margin-top: 20px;
      border: 1px solid $gray;
      color: $gray;
      background-color: #fff;
      transition: all 200ms ease-in-out;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &.active {
        color: $white;
        background-color: $accent;
        border-color: $accent;
      }
    }

    .CategoryItem {
      margin: 3px;
      padding: 9px;
      background-color: #eee;
      width: fit-content;
      transition: all 200ms ease-in-out;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      .del {
        background-color: #e5f8ff;
        position: absolute;
        display: none;
        margin-top: -42px;
        padding: 4px 15px;

        &:hover {
          color: $red;
        }
      }

      &:hover {
        .del {
          display: block;
        }

        background-color: #e5f8ff;
      }
    }
  }
}

.CreateUserRow,
.CategoriesList {
  padding: 40px;
  height: fit-content;
  padding-bottom: 40px;
  margin: 20px;
  border: 1px solid $gray;
}

.radio-block {
  margin-bottom: 30px;

  label {
    margin-bottom: 0;
    padding: 5px 20px;
    font-size: 14px;
    margin-right: 20px;
    border: 1px solid $gray;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      border-color: $darkgray;
    }

    &.active {
      border-color: $accent;

      &:hover {
        border-color: $accent;
      }
    }

    span {
      position: relative;
      top: -1px;
    }
  }

  input[type="radio"] {
    margin-right: 10px;
    min-width: fit-content;
  }
}

.href {
  cursor: pointer;
  text-decoration: none;
  color: $accent;

  &.loading {
    color: #52c36a;
  }

  &.del {
    color: #ff9e39;

    &:hover {
      color: #f53838;
    }
  }

  &:hover {
    color: #4695ce;
  }
}

.rs-table {
  font-size: 14px;
}

.rs-table-row {
  cursor: pointer;
}

.darkgray {
  color: $darkgray;
}

.green {
  color: #21a53d;
}

.actions {
  display: flex;
}

$span_height: 36px;

.span-container {
  display: block;
  overflow: hidden;
  cursor: pointer;
  width: fit-content;
  height: $span_height;
  max-height: $span_height;

  span {
    display: block;
    height: $span_height;
    color: $accent;
    width: fit-content;
  }

  &:hover {
    span {
      display: none;
    }

    &:after {
      display: block;
      color: #0056b3;
      cursor: pointer;
      content: "Оплатить";
    }

    &.payed:after {
      color: #ff9e39;
      content: "Снять оплату";
    }

    &.not-payed:after {
      color: #52c36a;
      content: "Проставить оплату";
    }
  }
}

.rs-table-row-expanded {
  input {
    background-color: transparent;
  }

  textarea {
    background-color: transparent;
    min-width: 710px;
  }
}

.ml-50 {
  margin-left: 50px;
}

.actions {
  gap: 10px;
}

.emailForm {
  display: flex;
  flex-direction: column;

  textarea {
    resize: none;
    height: 52px;
    padding: 9px 10px 12px 24px;
    flex: 1;

    &::placeholder {
      color: #ccc;
    }

    // line-height: 40px;
  }

  .Upload {
    height: 52px;
    padding: 10px 25px 12px 25px;
    width: fit-content;
  }
}

.fz-14 {
  font-size: 14px;
}

$lightred: #f12e50;
$darkred: #c80e3d;

.main {
  // background: $lightred;
  overflow: hidden;

  .title {
    color: #fff;
    background: $darkred;
    text-align: center;
    font-weight: 700;
    line-height: 1.2em;
    padding: 1em;
  }
}

.section {
  position: relative;
  width: 100%;
  border: 1px solid $gray;
  border-bottom: 1px solid transparent;
  margin-bottom: 10px;

  button {
    position: absolute;
    color: $accent;
    top: 13px;
    right: 20px;
    margin: 0;
    padding: 0;
    height: 2em;
    width: 2em;
    outline: 0;
    border: 0;
    background: none;
    text-indent: -9999%;
    pointer-events: none;

    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 12px;
      width: 4px;
      border-radius: 0.3em;
      background: $accent;
      transform-origin: 50%;
      top: 50%;
      left: 50%;
      transition: all 0.25s ease-in-out;
      transform: translate(75%, -50%) rotate(45deg);
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 12px;
      width: 4px;
      border-radius: 0.3em;
      background: $accent;
      transform-origin: 50%;
      top: 50%;
      left: 50%;
      transition: all 0.25s ease-in-out;
      transform: translate(-75%, -50%) rotate(-45deg);
    }
  }
}

.section.open {
  border-bottom: 1px solid $gray;

  button {
    &:before,
    &:after {
      height: 14px;
    }

    &:before {
      transform: translate(0%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(0%, -50%) rotate(45deg);
    }
  }

  .articlewrap {
    &.first {
      max-height: 450px;
    }

    &.second {
      max-height: 210px;
    }

    &.third {
      max-height: 340px;
    }
  }
}

.articlewrap {
  max-height: 0;
  // height: ;
  overflow: hidden;
  transition: all 0.4s ease-in;
}

.sectionhead {
  width: 100%;
  overflow: hidden;
  background: white;
  cursor: pointer;
  color: #888;
  // white-space: nowrap;
  text-overflow: ellipsis;
  padding: 1em;
  padding-right: 2.1em;
  border-bottom: 1px solid $gray;
}

.article {
  padding: 1em;
  color: #333;
  line-height: 1.3;
}

input.nice {
  position: sticky;
  display: block;
  z-index: 9999;
  background-color: white;
  top: 30px;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 500;
  padding: 20px 35px 24px 35px;
  transition: all 200ms linear;
  width: 100%;
  margin-top: 30px;

  &:hover,
  &:active,
  &:focus {
    background-color: white;

    border: 1px solid $accent !important;
  }
}

.privacy-policy {
  font-size: 16px;
  margin-top: 20px;

  a {
    font-weight: 500;
  }
}

.agrees {
  display: flex;
  cursor: pointer;
  margin: 20px 0;

  &:hover {
    .checkbox {
      outline-color: #3498db;
    }
  }

  .legaltext {
    font-size: 11px;
    line-height: 12px;
    color: #949494;
  }

  .checkbox {
    width: 15px;
    min-width: 15px;
    height: 15px;
    outline: 2px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: outline-color 500ms ease-in-out;
    margin: 5px 10px 0 0;

    &.checked {
      outline-color: #3498db;

      .check {
        background-color: #3498db;
      }
    }
  }

  .check {
    width: 13px;
    height: 14px;
    background-color: #fff;
  }
}

.top-row {
  position: relative;
}
.transparent {
  opacity: 0.4;
}

.documents {
  position: absolute;
  right: -120px;
  z-index: 9999;
  padding: 20px;

  .documents__tooltip {
    margin-left: 0 !important;
    color: #000;

    & > div {
      margin-bottom: 20px;
    }

    div {
      color: #000 !important;

      margin-left: 0 !important;
    }

    position: absolute;
    display: none;
    right: 0;
    top: 60px;
    box-shadow: 0 5px 30px #0002;

    max-height: 500px;
    overflow: auto;

    background-color: #fff;
    width: max-content;
    max-width: 600px;

    padding: 40px 60px 24px;
  }

  &:hover {
    .documents__tooltip {
      display: block;
    }
  }
}

@import "media";
