$default-font: "roboto-example", sans-serif;
$accent-opacity: #48b1fd;
$accent: #48b1fd;
$text: #333;
$white: rgb(255, 255, 255);
$gray: #e5e5e5;
$darkgray: #9c9c9c;
$gray-text: #c4c4c4;
$hover_gray: #f8f8f8;
$red: #f03838;
//Для блока
$border-radius: 5px;
$block-padding: 20px;
$block-width: 300px;

$block-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);

.category-padding {
  padding: 0 50px;
}

@mixin border($color, $tw: 1px, $bw: 1px, $lw: 1px, $rw: 1px) {
  border: 1px solid $color;
  border-width: $tw $rw $bw $lw;
}
@mixin icon($width, $height, $name) {
  display: block;
  text-indent: -9999px;
  width: #{$width}px;
  height: #{$height}px;
  background-color: $accent;
  -webkit-mask-image: url(../svg/#{$name}.svg);
  mask-image: url(../svg/#{$name}.svg);
  // background: url(../svg/#{$name}.svg);
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}
$icon_arr: (
  "radio",
  "input",
  "text",
  "close",
  "doc",
  "upload",
  "trash",
  "list",
  "check",
  "extensibleList"
);

@each $icon in $icon_arr {
  .#{$icon} {
    @include icon(20, 20, #{$icon});
  }
}
// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"

$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

$gutter: $grid-gutter-width;
