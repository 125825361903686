@import "_mixins/font-face";

@include font-face("roboto-example", "../../fonts/RobotoRegular/RobotoRegular");
@include font-face("roboto-example", "../../fonts/RobotoBold/RobotoBold", bold);
@include font-face("roboto-example", "../../fonts/RobotoMedium/RobotoMedium", 500);

@include font-face("MullerNarrow", "../../fonts/MullerNarrowExtraBold/MullerNarrowExtraBold", 500);
// @include font-face("MullerNarrow", "../../fonts/MullerNarrowExtraBoldItalic/MullerNarrowExtraBoldItalic", 600);

// @include font-face("MullerNarrow", "../../fonts/MullerNarrowLight/MullerNarrowLight", 100);
// @include font-face("MullerNarrow", "../../fonts/MullerNarrowLightItalic/MullerNarrowLightItalic", 200);

