.container {
  display: flex;
  margin: 30px 0 10px 0;
  align-items: center;
}

.input {
  border: 1px solid #eee !important;
  padding: 14px 20px 16px !important;
  padding: 10px;
  transition: all 100ms linear;
  border-radius: 10px;
  width: 100%;
  margin-right: 20px;
  height: 100%;
  &:hover,
  &:focus,
  &:active {
    border-color: var(--accent) !important;
  }
}
