.categories {
  &__search {
    width: 90%;
    &-wrapper {
      position: relative;
      width: 100%;
      margin-right: 20px;
      input {
        max-width: 100%;
      }
    }
    &-results {
      overflow-y: scroll;
      position: absolute;
      width: 100%;
      height: 400px;
      z-index: 1000;
      background-color: $white;
      border: 2px solid $gray;
      padding: 10px;
    }
  }
  .long-input {
    width: 200px;
  }
  &-overlay {
    z-index: 10000;
  }
  .paginate-disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
  }
  .disabled {
    display: none !important;
  }
  background-color: #fafafa;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  // flex-wrap: wrap;
  // margin: 0 0 20px 20px;
  // position: absolute;
  overflow: visible;
  // height: calc(100vh + -31px);

  input {
    width: 100%;
    font-size: 11pt;
  }
  .link {
    font-size: 11pt;
    color: $gray-text !important;
    h5 {
      &:first-child {
        pointer-events: none;
      }
      &:hover {
        &:last-child {
          cursor: pointer;
          color: $accent !important;
        }
      }
    }
  }
  h5 {
    color: $gray-text;
    font-size: 11pt;
    font-weight: 100;
  }
  .category-card {
    // background: $white;
    // box-shadow: $block-shadow;
    // margin: 20px;
    width: 100%;
    height: fit-content;
    padding: 30px 0 50px 0;
    .border-left {
      border-left: 3px solid $accent !important;
    }
    .text-blue {
      color: $accent;
    }
    .close-icon {
      margin: 0;

      div {
        margin: 0;
        right: auto;
      }
    }
    // &:hover {
    // outline: 1px solid $accent;
    // }
  }
}
input[type="file"] {
  cursor: pointer;
  border: none !important;
  + label {
    justify-content: start;
  }
}
form {
  margin-top: 10px;
  input,
  button {
    cursor: pointer;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid $accent !important;
  }
  button {
    &:hover {
      background-color: $accent;
      color: $white;
      .icon {
        background-color: $white;
      }
    }
  }
}

.category {
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: absolute;
  .workspace {
    position: fixed;
    left: 0;
    right: 300px;
    bottom: 0px;
    top: 30px;
    overflow: auto;
    &::-webkit-scrollbar {
      background-color: $white;
      border: 1px solid $gray;
      margin-bottom: 20px;
      padding: 20px;
      z-index: -1;
    }
    &::-webkit-scrollbar-thumb {
      z-index: -1;
      border: 1px solid gray;
      &:hover {
        // border-color: $white;
        background: $accent;
      }
    }
    .ScriptsBox {
      position: fixed;
      top: 40px;
      right: 330px;
      background: $white;
      h3 {
        color: $accent;
        float: left;
      }
      .edit {
        float: right;
        margin: 9px 0;
      }
      .minimizeBox {
        padding: 10px;
        // padding-bottom: 0;
        position: relative;
        bottom: -5px;
        right: -6px;
        cursor: pointer;
        // background: $text;
        float: right;
        &:hover {
          .minimize {
            background: $accent;
          }
        }
      }
    }

    .Scripts {
      transition: all 300ms ease-in-out;
      width: 300px;
      max-height: 100%;
      background-color: $white;
      box-shadow: $block-shadow;
      overflow-y: auto;
      &.collapsed {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        max-height: 40px !important;
        color: transparent;
        overflow: hidden !important;
        .edit {
          background: transparent;
        }
      }
      p {
        padding: 0 10px;
        margin: 0;
        line-height: 40px;
        height: 40px;
      }

      .script {
        .title {
          background-color: #fafafa;
          // font-weight: bold;
        }
        .doc {
          border-bottom: 1px solid $gray;
          border-top: 1px solid $gray;
          padding-left: 20px;
          display: block;
          word-wrap: none;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &::-webkit-scrollbar {
        background-color: $white;
        border: 1px solid $gray;
        margin-bottom: 20px;
        padding: 20px;
        z-index: -1;
      }
      &::-webkit-scrollbar-thumb {
        z-index: -1;
        border: 1px solid gray;
        &:hover {
          // border-color: $white;
          background: $accent;
        }
      }
    }
    .DocsBlock {
      position: absolute;
      width: max-content;
      box-shadow: $block-shadow;
      background: $white;
      flex-direction: column;
      display: flex;
      min-height: 50px;
      transition: all 100ms ease-in-out;
      border-radius: $border-radius;
      outline: 1px solid $gray;
      &:hover {
        outline-color: $accent;
      }
      .Doc {
        // justify-content: flex-start;
        display: flex;
        width: 300px;
        outline: 1px solid $gray;
        flex-direction: column;
        color: $text;
        padding: 10px 20px;
        background-color: $white;
        &:last-child {
          .times {
            display: none;
          }
        }
        p {
          margin: 0;
        }
        // align-items: center;
        &:hover {
          background-color: #f0f0f0;
        }
        .id {
          position: absolute;
          left: 10px;
          color: $text;
        }
        .times {
          position: absolute;
          cursor: pointer;
          -webkit-mask-image: url("/icons/times.svg");
          mask-image: url("/icons/times.svg");
          // -webkit-mask-size: cover;
          // mask-size: cover;
          -webkit-mask-position: center;
          mask-position: center;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          background-color: $text;
          width: 24px;
          height: 24px;
          left: 270px;
          margin-top: -7px;
          // padding: 6px;

          &:hover {
            background-color: $red;
          }
        }

        .title {
          color: $text;
          // font-weight: bold;
        }
      }
    }
  }

  .filename,
  .title {
    height: 30px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .TemplatesPanel {
    position: fixed;
    right: 0;
    top: 30px;
    bottom: 0;
    height: 100%;
    background: $white;
    width: 300px;
    border-left: 1px solid $gray;

    .template {
      padding: 10px 20px;
      background: #fff;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      width: 100%;
      &:nth-child(2n) {
        background: #fafafa;
      }
      outline: 1px solid $gray;
      p {
        margin: 0;
      }
      .title {
        // font-weight: bold;
      }
    }
  }
}

.minimize {
  cursor: pointer;
  -webkit-mask-image: url("/icons/minimize.svg");
  mask-image: url("/icons/minimize.svg");
  // -webkit-mask-size: cover;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: $text;
  width: 12px;
  height: 16px;
  &:hover {
    background-color: $accent;
  }
}
.edit {
  cursor: pointer;
  -webkit-mask-image: url("/icons/edit.svg");
  mask-image: url("/icons/edit.svg");
  // -webkit-mask-size: cover;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: $text;
  width: 22px;
  height: 22px;
  &:hover {
    background-color: $accent;
  }
}

.ScriptScreen {
  padding-bottom: 30px;
  height: calc(100vh - 30px);
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  .ScriptScreen {
    & > .ElementContainer {
      margin-bottom: 0px;
    }
  }
}
.ElementContainer {
  min-height: 100px;
  /* overflow: auto; */
  max-width: 800px;
  padding: 0 0 0 15px;
  display: flex;
  flex-direction: row;
  display: flex;
  flex-direction: column;
  border-left: 2px solid $gray;
  margin-top: 10px;
  margin-bottom: 20px;
  &:hover {
    border-left-color: $accent;
  }
  .Element {
    width: -webkit-fill-available;
    display: flex;
    // padding: 0 0 0 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    // border-left: 2px solid transparent;
    margin-bottom: 20px;

    &:hover,
    &:focus,
    &:active {
    }
    .labelRadio {
      cursor: pointer;
      padding: 2px 12px 2px 0px;
      color: $darkgray;
      width: fit-content;
      margin-right: 22px;
      &:hover {
        color: $accent;
      }
    }
    .activeLabel {
      color: $accent;
    }
    .arrows {
      position: relative;
      top: 0px;
      right: -28px;
      * {
        color: $accent;
        cursor: pointer;
        :hover {
          opacity: 0.7;
        }
      }
    }
    &:hover {
      border-bottom-color: $accent;
      .icon {
        background: $accent;
      }
    }
  }
  &:hover {
    border-bottom-color: $accent;
    .icon {
      background: $accent;
    }
  }
}
svg {
  height: 20px;
  &:hover {
    color: gray;
  }
}

.icon {
  cursor: pointer;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: $text;
  width: 22px;
  height: 22px;
  &:hover {
    background-color: $accent;
  }
}
.input {
  width: 22px;
  height: 22px;
  -webkit-mask-image: url("/icons/input.svg");
  mask-image: url("/icons/input.svg");
  // -webkit-mask-size: cover;
  // mask-size: contain;
}

.QScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  width: 80%;
  height: 700px;
  background: $white;
  box-shadow: $block-shadow;
  overflow: auto;

  .times {
    cursor: pointer;
    -webkit-mask-image: url("/icons/times.svg");
    mask-image: url("/icons/times.svg");
    // -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background-color: $text;
    width: 14px;
    height: 14px;
    // align-self: flex-end;
    position: relative;
    // top: -12px;
    // right: 15px;
    // padding: 6px;
    &:hover {
      background-color: $red;
    }
  }
  .btn-group {
    width: 300px;
    // margin: 0 auto;
    position: relative;
    align-self: flex-start;
    justify-content: flex-start;
    display: flex;
    margin-left: 30px;
    .btn {
      color: $text;
      background: #fafafa;
      margin: 0 2px;
      position: relative;
      border: 1px solid $gray;
      border-radius: 10px 10px 0px 0px !important;
      height: 100%;
      width: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      position: relative;
      bottom: -1px;
      .times {
        background: #bbb;
        margin-left: 30px;
        &:hover {
          background: $red;
        }
      }
      &:active,
      &:focus {
        outline: none;
      }
      &:hover {
        border-color: $accent;
      }
    }
    .plus-btn {
      background: #fafafa;
      display: flex;
      align-items: center;
      border: 1px solid $gray;
      border-radius: 10px 10px 0 0 !important;
      justify-content: center;
      cursor: pointer;
      position: relative;
      bottom: -9px;
      height: 30px;
      width: 40px;
      &:hover {
        border-color: $accent;
        .plus {
          // background: $white !important?;
        }
      }
    }
    .plus {
      transition: all 100ms ease-in-out;
      width: 22px;
      height: 22px;
      background: $accent !important;
      padding: 0;
      -webkit-mask-image: url("/icons/plus.svg");
      mask-image: url("/icons/plus.svg");
      mask-position: center;
      // -webkit-mask-size: contain;
      // mask-size: contain;
      mask-repeat: no-repeat;
    }
  }
  .Dropable {
    width: 100%;
    min-height: 400px;
    max-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top: 1px solid $gray;
    overflow: auto;
    padding: 50px;
    &::-webkit-scrollbar {
      background-color: $white;
      border: 1px solid $gray;
      margin-bottom: 20px;
      padding: 20px;
      z-index: -1;
    }
    &::-webkit-scrollbar-thumb {
      z-index: -1;
      border: 1px solid gray;
      &:hover {
        // border-color: $white;
        background: $accent;
      }
    }
    border-bottom: 1px solid $gray;
    .Element {
      width: max-content;
      flex-direction: row;
      display: flex;
      align-items: center;
      height: 50px;
      background: $white;
      transition: all 200ms ease-in-out;
      margin: 20px auto !important;

      .Input {
        margin-left: 10px;
        width: 250px;
        border-bottom: 1px solid $gray;
        cursor: auto;
      }
      .icon {
        float: left;
      }
      p {
        margin: 0;
      }

      // margin: 20px;
    }
  }
}
.Upload {
  .upload {
    margin-right: 10px;
    background-color: #fff;
  }
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  border: none;
  background-color: $accent;
  margin-top: 10px;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
  }
}
.form-check {
  padding-left: 0;
  padding-right: 1.25rem;
  display: flex;
  align-items: center;
}
.TestWebSite {
  width: 1300px;
  background-color: #fff;
  // overflow-y: auto;
  padding: 50px 50px 100px 100px;
  margin: 0 auto;
  .content {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    .inputsBlock {
      display: flex;
      flex-direction: column;
      border: 1px solid $gray;
      padding: 20px;
    }
    .deleteInputsBlock {
      border: none;
      position: relative;
      top: 5px;
      right: 5px;
    }
  }
  h1 {
    font-size: 24pt;
    width: 500px;
    font-weight: bold;
    // transform: rotate(-30deg);
  }

  .flag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 20px;
    margin: 20px 0;
    min-width: 300px;
    width: max-content;
    box-shadow: $block-shadow;
    transition: all 400ms ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
    .icon {
      margin-right: 20px;
    }
  }
}
.getDocs {
  cursor: pointer;
  padding: 20px 40px;
  border-radius: 5px;
  background-color: $accent;
  color: $white;
  &:hover,
  &:active,
  &:focus {
    background-color: #7f4896;
  }
}

.templateBlock {
  // padding-top: 15px !important;
  padding-bottom: 15px !important;
  &:nth-child(2n) {
    // border-top: 1px solid #b8b8b8;
    // border-bottom: 1px solid #b8b8b8;

    input {
      background-color: #f2f2f2;
    }
  }
}
label {
  margin-bottom: 0;
}
.title {
  font-weight: bold;
  min-width: 500px;
  margin: 0 0;
  display: flex;
  .icon {
    margin-left: auto;
  }
}
.justify-content-space-between {
  .icon {
    margin-left: auto;
  }
}
.icon {
  &.radio,
  &.list,
  &.input {
    cursor: default;
  }
}
.RadioElement {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2px;
  .cbx {
    position: relative;
    display: block;
    float: left;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    border-radius: 4px;
    background-color: $gray;
    box-shadow: inset 0 1px 1px rgba(white, 0.15),
      inset 0 -1px 1px rgba($gray, 0.15);
    transition: all 0.15s ease;
    svg {
      position: absolute;
      top: 3px;
      left: 3px;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: white;
      stroke-width: 2;
      stroke-dasharray: 17;
      stroke-dashoffset: 17;
      transform: translate3d(0, 0, 0);
    }
  }

  .cbx + span {
    float: left;
    margin-left: 6px;
  }

  .label {
    display: inline-block;
    // margin: 10px;
    cursor: pointer;
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  input[type="checkbox"]:checked + .cbx {
    background-color: $blue;
    // background-image: linear-gradient(#{$blue +}, $blue);
    svg {
      stroke-dashoffset: 0;
      transition: all 0.15s ease;
    }
  }
}
.form-check {
  label {
    cursor: pointer;
  }
  .radio1 {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    font-size: 14px;
    margin: 15px;
    .label {
      position: relative;
      display: block;
      float: left;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      border: 2px solid $gray;
      border-radius: 100%;
      -webkit-tap-highlight-color: transparent;
      &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: $blue;
        transform: scale(0);
        transition: all 0.2s ease;
        opacity: 0.08;
        pointer-events: none;
      }
      // &:hover {
      //   .label::after {
      //     transform: scale(3.6);
      //   }
      // }
    }
  }
}

input[type="radio"]:checked + .label {
  border-color: $blue;
  &::after {
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
  }
}

.m-0 {
  margin-left: 0;
  margin-right: 0;
}
.bold {
  font-weight: bold;
}

.TitleRow {
  display: flex;
  flex-direction: column;

  .top-row {
    font-size: 11pt;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $darkgray;
    .right {
      display: flex;
      align-items: center;
      div {
        cursor: pointer;
        margin-left: 22px;
        &:hover {
          color: $accent;
        }
      }
    }
  }
}
.Filename {
  .bold {
    font-weight: 500;
  }
}
.category-padding {
  display: none !important;
}
.href {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.tip-input {
  font-style: italic;
}
