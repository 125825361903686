.button {
  cursor: pointer;
  min-width: 210px;
  padding: 14px 20px 16px;
  text-align: center;
  font-weight: 500;
  border-radius: 10px;

  background-color: #fff;

  border: 1px solid var(--accent);
  color: var(--accent);
  margin-right: 20px;

  &:hover {
    background-color: var(--accent);
    color: white;
  }
}
