.category {
  background-color: white;

  width: 90%;

  margin: 20px 0;
  border-radius: 10px;
  cursor: default;

  border: 1px solid #eee;

  h3 {
    margin: 0 !important;
  }
}

.links {
  display: flex;
}

.actions {
  padding: 0 40px 0;
}

.titleContainer {
  border-radius: 10px;
  width: 100%;

  padding: 30px 40px 32px;

  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;
  display: flex;
  justify-content: space-between;

  box-shadow: 0 6px 20px rgba($color: #000000, $alpha: 0.03);
  &:hover {
    h3 {
      color: #48b1fd;
    }
  }
}

.copyProcess {
  padding: 30px 40px 32px;
  color: #999;
  font-weight: 500;
  cursor: default;
}

.copyContainer {
  padding-top: 40px;
  align-items: center;
  display: flex;
  select {
    width: 100% !important;
    padding: 14px 20px 16px !important;
  }
}

.documentsTitle {
  border-top: 1px solid #eee;
  padding: 30px 40px 0;
  margin: 40px -40px 0;
}
