.container {
  padding: 20px 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  // margin: 0 -40px;
  border-bottom: 1px solid #eee;

  &:last-child {
    border: none;
  }
}

.titleBlock {
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;
}

.edit {
  position: absolute;
  cursor: pointer;
  left: -30px;
  img {
    width: 20px;
  }
}

.title {
  font-size: 18px;
  font-weight: 400;
  margin-right: 30px;
  transition: all 100ms linear;
}

.buttons {
  display: flex;
  // width: 100%;
  margin-left: auto;
  div {
    &:first-child {
      margin-left: 0;
    }
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
}

.fileUpload {
  position: relative;
  margin-right: auto;
  overflow: hidden;
}

.filename {
  font-size: 14px;
  display: flex;
  color: #999;
  margin-left: auto;
  justify-content: flex-end;
}
