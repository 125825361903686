@import "vars";

/*==========  Desktop First  ==========*/

// ≥1200px
@include media-breakpoint-down(lg) {
}

// ≥992px
@include media-breakpoint-down(md) {
  .Oprosnik.TestWebSite {
    padding: 24px;
  }
}

// ≥768px
@include media-breakpoint-down(sm) {
  .Oprosnik.TestWebSite {
    padding: 16px;
  }
}

// ≥576px
@include media-breakpoint-down(xs) {
}

/*==========  Mobile First  ==========*/

// ≤576px
@include media-breakpoint-up(sm) {
}

// ≤768px
@include media-breakpoint-up(md) {
}

// ≤992px
@include media-breakpoint-up(lg) {
}

// ≤1200px
@include media-breakpoint-up(xl) {
}
