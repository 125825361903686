$w: 400px;
$h: 200px;

.error-screen {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  text-align: left;
  margin: auto;
  @include left(50%, $w/2);
  @include top(50%, $h/2);
  width: $w;
  height: $h;
  background: $white;
  box-shadow: $block-shadow;
  padding: 40px 50px;
  border-radius: $border-radius;
  .h2 {
    font-weight: bold;
    margin-bottom: 0;
  }
  .h3 {
    margin: 10px 0;
  }
}
