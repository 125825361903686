@import "../../scss/vars";

.TestWebSite {
  h1 {
    font-size: 36px;
    padding: 0px 35px 4px 35px;
  }

  .not-found {
    padding: 20px 35px 24px 35px;
    font-size: 20px;
  }
  .script {
    margin-top: -1px;
  }
}
.script {
  height: fit-content;
  border-radius: 8px;
  transition: all 200ms linear;
  display: flex;
  padding: 20px 10px 20px 35px;
  align-items: center;

  &:hover {
    background-color: #f8f8f8;
  }

  &__title {
    font-size: larger;
    // font-weight: bold;
    font-size: 20px;
    width: 550px;
    margin-right: 50px;
    line-height: 170%;
  }

  .title {
    &__number {
      color: #aaa;
    }
  }

  &__actions {
    display: flex;
    * {
      margin-right: 30px;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      transition: all 200ms linear;
      cursor: pointer;

      &:hover {
        text-decoration: none;
        color: #0055ff;
      }
    }
  }
  .actions {
    &__alert {
      position: absolute;
      background-color: white;
      box-shadow: 0 0 60px rgba(0, 0, 0, 0.08);
      padding: 14px 20px 18px 20px;
      border-radius: 4px;
      z-index: 99999999999;
      cursor: default;
      text-align: center;
      width: max-content;
      &.success {
        width: 240px;
        color: #1ac147 !important;
        top: -80px;
        left: -90px;
      }
      &.fail {
        width: 371px;
        color: #333 !important;
        top: -110px;
        left: -170px;
      }
    }
    &__href {
    }
    &__get-docs {
    }
    &__edit {
    }
    &__delete {
      color: #999;
      &:hover {
        color: $red;
      }
    }
  }
}
