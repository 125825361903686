.input {
  cursor: pointer;
  padding: 14px 20px 16px;
  border: 1px solid #eee !important;
  border-radius: 10px;

  background-color: #fff !important;

  margin: 10px 0;
  width: 100%;
  max-width: 90%;
  font-size: 18px !important;

  &:hover,
  &:active,
  &:focus {
    border-color: var(--accent) !important;
  }

  &::placeholder {
    color: #999;
  }
}
