$loginBlockWidth: 400px;
$loginBlockHeight: 400px;

@mixin left($subtrahend, $minuend) {
  left: -webkit-calc(#{$subtrahend} - #{$minuend});
  left: -moz-calc(#{$subtrahend} - #{$minuend});
  left: calc(#{$subtrahend} - #{$minuend});
}
@mixin top($subtrahend, $minuend) {
  top: -webkit-calc(#{$subtrahend} - #{$minuend});
  top: -moz-calc(#{$subtrahend} - #{$minuend});
  top: calc(#{$subtrahend} - #{$minuend});
}

.login-block {
  position: absolute;
  display: flex;
  align-self: center;
  // justify-content: center;
  margin: auto;
  @include left(50%, $loginBlockWidth/2);
  // @include top(50%, $loginBlockHeight/2);
  width: $loginBlockWidth;
  height: $loginBlockHeight;
  background: $white;
  // box-shadow: $block-shadow;
  border-radius: $border-radius;
  z-index: 2;
  .error {
    padding: 3px 10px;
    position: absolute;
    background: $white;
    color: $red;
    @include border($red);
    width: $loginBlockWidth;
    z-index: -1;
    transition: all 300ms ease-in-out;
    span {
      width: 100%;
    }
    .times {
      position: absolute;
      right: 10px;
      top: 7px;
    }
  }
  .enter-button {
    width: 100%;
    max-width: 140px;
    background: $accent;
    outline: 0;
    border: 0;
    height: 40px;
    border-radius: $border-radius;
    cursor: pointer;
    margin-top: 30px;
    text-align: center;
    -webkit-appearance: none;
    &:hover {
      background-color: #{$accent + "cc"};
    }
    a {
      text-decoration: none;
      color: $white;
    }
    span {
      line-height: 40px;
    }
  }
  .title {
    background: $white;
    padding-left: 60px;
    font-weight: bold;
    text-align: center;
    padding-top: 50px;
    min-width: inherit;
  }

  flex-direction: column;
  form {
    width: 100%;
    display: flex;
    margin: 20px auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    * {
      margin-top: 10px;
    }

    .inp {
      position: relative;
      width: 100%;
      max-width: 280px;
      .label {
        position: absolute;
        top: 16px;
        left: 0;
        font-size: 16px;
        color: $text;
        pointer-events: none;
        font-weight: 500;
        transform-origin: 0 0;
        transition: all 0.2s ease;
      }
      .border {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 100%;
        background: $accent;
        transform: scaleX(0);
        transform-origin: 0 0;
        transition: all 0.15s ease;
      }
      input {
        -webkit-appearance: none;
        width: 100%;
        border: 0;
        font-family: inherit;
        padding: 12px 0;
        height: 48px;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 2px solid #c8ccd4;
        background: none;
        border-radius: 0;
        color: #223254;
        transition: all 0.15s ease;
        &:hover {
          background: rgba(34, 50, 84, 0.03);
        }
        &:not(:placeholder-shown) + span {
          color: #5a667f;
          transform: translateY(-26px) scale(0.75);
        }
        &:focus {
          background: none;
          outline: none;
        }
        &:focus + span {
          color: $accent;
          transform: translateY(-26px) scale(0.75);
        }
        &:focus + span + .border {
          transform: scaleX(1);
        }
      }
    }
  }
}
